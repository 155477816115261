//This is a temporary workaround for some pages not scrolling to the top of the screen when opened.
//This can also be used for transition animations by increasing the delay.
//Issue documentation can be found here: https://github.com/gatsbyjs/gatsby/issues/38201
const transitionDelay = 1;
export const shouldUpdateScroll = ({ 
    routerProps: { location }, 
    getSavedScrollPosition 
 }) => { 
    if (location.action === "PUSH") { 
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay); 
    } else { 
        const savedPosition = getSavedScrollPosition(location); 
        window.setTimeout( 
            () => window.scrollTo(...(savedPosition || [0, 0])), 
            transitionDelay 
        ); 
   } 
   return false; 
 }; 